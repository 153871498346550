import { api, BadRequestError, responseErrorCheck, SimpleItemsListRequest, TmrBaseResource } from 'stylewhere/api'
import { AppStore } from 'stylewhere/shared'
import { ApiErrorResponse, ApiResponse, create } from 'apisauce'
import { __, T } from 'stylewhere/i18n'
import { ConnectionError, Unauthorized } from 'stylewhere/shared/errors'

export class SimpleItemList extends TmrBaseResource {
  static endpoint = 'api/v1/operations/simpleItemList' //tracking/

  static batchValidateEndpoint() {
    return `${this.endpoint}/batchValidate`
  }

  static async executeAction(listId: string, operationId: string, actionCode: string, payload: any) {
    const decodePayload = {
      operationId: operationId,
      actionCode: actionCode,
      ...payload,
    }
    decodePayload.attributes.workstationCode = AppStore.defaultWorkstation?.code
    const res = await api.post(`${this.endpoint}/${listId}/executeAction `, decodePayload)
    return responseErrorCheck(res)
  }

  static relabeling(request: SimpleItemsListRequest) {
    return api.post<any>(`${this.endpoint}`, request).then(SimpleItemList.customResponseErrorCheck)
  }

  static async print(zpl: string, endpoint: string) {
    const printerEndpoint = create({
      baseURL: 'http://' + endpoint,
      timeout: 3000,
    })
    printerEndpoint.setHeaders({
      Accept: 'application/x-www-form-urlencoded',
      'Content-Type': 'application/x-www-form-urlencoded',
    })
    return printerEndpoint.post(`pstprnt`, zpl).then(responseErrorCheck)
  }

  static customResponseErrorCheck = <T = any>(res: ApiResponse<T, any>) => {
    if (res?.status === 0 || res?.problem === 'UNKNOWN_ERROR') {
      throw new ConnectionError(res as ApiErrorResponse<any>)
    }

    if (res && (res.status === 200 || res.status === 204)) {
      return res.data as T
    }

    if (res.status === 500) {
      throw new Error(res.data && res.data.message ? res.data.message : 'Internal Server Error')
    }

    if (
      res.status === 400 &&
      res.config?.url?.indexOf('simpleItemSessionList') !== -1 &&
      res.config?.url?.indexOf('executeAction ') !== -1
    ) {
      throw new Error('')
    }

    if (res.data && res.data.errorCode === 'UNPACK') {
      throw new Error('UNPACK')
    }

    if (res.status === 401 && res.config?.url === '/login') {
      // showNotification('Errore', 'Credenziali errate', 'error')
      throw new Error('Credential error')
    }

    if (res.status === 401) {
      // showNotification('Errore', 'Non autorizzato', 'error')
      throw new Error('Not authorized')
    }

    if (res.status === 403) {
      throw new Unauthorized(res as ApiErrorResponse<any>)
    }

    if (res.status === 404) {
      throw new Error(res?.data?.message ?? '404 Error')
    }

    if (res?.status && res?.status >= 400) {
      if (res.data && res.data.errorCode === 'UNMATCHED_IDENTIFIERS_ERROR_CODE') {
        const message = res.data.message
        throw new Error(message)
      }
      const errorTranslation = __(T.serverError[res.data?.errorCode])
      if (errorTranslation) {
        throw new Error(errorTranslation)
      }

      if (res.data && res.data.payload && res.data.payload.info) {
        let message = ''
        let title = __(T.error.error)
        if (
          res.data.payload.info.log &&
          Array.isArray(res.data.payload.info.log) &&
          res.data.payload.info.log.length > 0
        ) {
          for (let l = 0; l < res.data.payload.info.log.length; l++) {
            message += (message !== '' ? ' - ' : '') + res.data.payload.info.log[l].message
          }

          if (res.data.payload.info.message) {
            title = res.data.payload.info.message
          }
        } else if (res.data.payload.info.message) {
          message = res.data.payload.info.message
        }

        if (message !== '') {
          throw new BadRequestError(message, title)
        }
      }

      const errorHasCode = res.data?.errorCode !== undefined || res.data?.messageCode !== undefined
      throw new Error(
        `${res.data?.errorCode ?? res.data?.messageCode ?? ''}${errorHasCode ? ': ' : ''}${
          res.data?.message ?? 'Generic Error'
        }`,
      )
    }

    if (res.data && res.data.error?.message) {
      // showNotification(res.data.error.message, 'danger')
      console.log('qui')
      throw new Error('Error')
    }

    if (!res || !res.data) {
      // showNotification('Malformed Response from server', 'danger')
      throw new Error('Malformed Response from server')
    }

    if (res.problem) {
      // showNotification(res.data?.message || res.problem, 'danger')
      throw new Error('Error')
    }
    return res.data
  }
}
