import React, { Component } from 'react'
import { TmrItem, ProductInfoField, IdentifierType, TmrSimpleAttributesMap } from 'stylewhere/api'
import { T, __ } from 'stylewhere/i18n'
import { ItemInfoModal as CoreItemInfoModal } from 'core/components/modals/ItemInfoModal'

interface Props {
  onClose: () => void
  item?: TmrItem
  itemId?: string
  visible: boolean
  hideIdentifiersByType?: IdentifierType[]
}

export class ItemInfoModal extends Component<Props> {
  customProductFields: ProductInfoField[] = [
    { path: 'code', label: __(T.misc.upc) },
    { paths: ['color.value', 'color.description'], label: __(T.misc.color) },
    { paths: ['size.value', 'size.description'], label: __(T.misc.size) },
    { path: 'style.value', label: __(T.misc.style) },
    { path: 'attributes.variantType.value', label: __(T.misc.typology) },
  ]

  getCustomItemAttributes = (item?: TmrItem): TmrSimpleAttributesMap[] => {
    if (!item || !item.zone) return []

    const placeDescription = item.zone.place?.description || item.zone.place?.code || ''
    const zoneDescription = item.zone.description || item.zone.code || ''

    if (!placeDescription && !zoneDescription) return []

    const zoneValue = `${placeDescription} - ${zoneDescription}`.trim()

    return [
      {
        label: __(T.misc.zone),
        value: zoneValue,
        key: 'zone-attribute',
      },
    ]
  }

  render() {
    return (
      <CoreItemInfoModal
        {...this.props}
        overrideProductInfoFields={this.customProductFields}
        showIdentifierStatus={true}
        sortIdentifiersBy={['serial', 'regular', 'data_matrix']}
        excludeAttributeMappings={['variantType', 'barcode']}
        skipIdentifierFiltering={true}
        customItemAttributes={this.getCustomItemAttributes}
      />
    )
  }
}
