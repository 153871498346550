import Base from 'core/pages/SimpleParcelList/SimpleParcelListReading'
import { Spinner } from '@chakra-ui/react'
import { ShippingParcel, DecodedParcel, SimpleParcelList } from 'stylewhere/api'
import {
  OperationReadingState,
  OperationReadingProps,
  RfidReader,
  getDataFromSchema,
  AppStore,
} from 'stylewhere/shared'
import { AntennaButton, Box, Page, SmallTextCounter, Spacer, TagCounter } from 'stylewhere/components'
import { T, __ } from 'stylewhere/shared/i18n'
import styled from '@emotion/styled'
import { SimpleParcelListExtensions } from 'stylewhere/extensions'
import { showToast, showToastError, askUserConfirmation } from 'stylewhere/shared/utils'
import { UserPinModal } from 'custom/cucinelli/components/modals/UserPinModal'
import { Parcels } from 'custom/cucinelli/api/Parcels'
import { GroupedParcelList } from 'custom/cucinelli/components/GroupedParcelList'

interface State extends OperationReadingState {
  parcelsByAsn: { [asnCode: string]: ShippingParcel[] }
  identifierCodesByParcel: { [parcelId: string]: string[] }
  unexpectedDecodedParcels: { [epc: string]: DecodedParcel }
  ignoredEpcs: any[]
  loadingParcel: boolean
  forcedParcelCode?: string[]
  visibleUserPin?: boolean
  confirming: boolean
}

export default class SimpleParcelListReading extends Base<OperationReadingProps<State>, State> {
  listParcels: ShippingParcel[] = []
  page = 0
  isModal = false

  async componentDidMount() {
    await super.componentDidMount()
    this.setState({ parcelsByAsn: { ['']: [] }, loading: false }, this.loadParcels)
  }

  loadParcels = () => {
    this.listParcels = []
    this.page = 0
    if (this.operation.options.customExtensionCode === 'cucinelliOutboundCertificato') {
      this.setState({ loadingParcel: true, forcedParcelCode: [], visibleUserPin: false }, this.getParcels)
    }
  }

  getParcels = async () => {
    const { formData } = this.state
    if (formData && formData.shipper) {
      const parcels = await Parcels.search<ShippingParcel>({
        palletCode: formData.shipper.code,
        size: 75,
        page: this.page,
      })
      if (parcels && parcels.content) {
        parcels.content.map((parcel) => this.listParcels.push(parcel))
        if (parcels.last || this.page === parcels.totalPages - 1) {
          for (let l = 0; l < this.listParcels.length; l++) {
            if (
              this.listParcels[l].attributes &&
              this.listParcels[l].attributes.outboundCertificatoForceUserId &&
              this.listParcels[l].attributes.outboundCertificatoForceAuthorisingUserId &&
              this.listParcels[l].attributes.outboundCertificatoForceDate
            )
              this.listParcels[l].__detected = true
            this.listParcels[l].__forced = true
          }
          this.setState({ parcelsByAsn: { ['']: this.listParcels }, loadingParcel: false })
        } else {
          this.page++
          this.getParcels()
        }
      }
    } else {
      this.setState({ loadingParcel: false })
    }
  }

  onClear = async () => {
    this.setState(
      {
        items: [],
        ignoredEpcs: [],
        identifierCodesByParcel: {},
        unexpectedDecodedParcels: {},
      },
      this.loadParcels
    )
    if (this.locationState.tags && this.locationState.tags.length > 0) {
      RfidReader.emulateTags(this.locationState.tags?.map((tag) => tag.epc) ?? [])
    }
  }

  decodeFunction = async (identifierCodes: string[]) => {
    const { ignoredEpcs } = this.state
    const decodePayload = getDataFromSchema(this.state.formData, this.formSchema)
    const result = await Parcels.decode({
      /** @todo Ripristinare operationId quando il BE supporterà le extensions per la SPL */
      // operationId: this.operation.id,
      identifierCodes,
      ...decodePayload,
    })
    const { parcelsByAsn, identifierCodesByParcel, unexpectedDecodedParcels } = this.state

    const newParcelsByAsn = { ...parcelsByAsn }

    Object.keys(result).map(async (tagCode) => {
      const decodedParcel = result[tagCode]
      if (!decodedParcel.item) {
        ignoredEpcs.push(tagCode)
      } else {
        const parcel = newParcelsByAsn[''].find((p) => p.code === decodedParcel.parcelCode)
        if (!parcel) {
          if (!(tagCode in unexpectedDecodedParcels)) {
            unexpectedDecodedParcels[tagCode] = decodedParcel
          }
        } else {
          if (!parcel.__detected) {
            if (parcel && decodedParcel.item !== null) {
              parcel.__detected = true
              parcel.__quantityRead = (parcel.__quantityRead ?? 0) + 1
              if (identifierCodesByParcel[parcel.id]) {
                const tmp: any[] = identifierCodesByParcel[parcel.id]
                identifierCodes.map((identifier) => tmp.push(identifier))
                identifierCodesByParcel[parcel.id] = tmp
              } else identifierCodesByParcel[parcel.id] = identifierCodes
            }
          }
        }
      }
    })
    this.setState({ parcelsByAsn: newParcelsByAsn, identifierCodesByParcel, ignoredEpcs })
  }

  onConfirm = async () => {
    const { forcedParcelCode } = this.state
    if (forcedParcelCode && forcedParcelCode.length > 0) {
      this.setState({ visibleUserPin: true })
    } else {
      this.setState({ confirming: true }, this._doConfirm)
    }
  }

  userPinConfirm = async (pin: string) => {
    const { forcedParcelCode } = this.state
    this.userPinClose()
    if (forcedParcelCode && forcedParcelCode.length > 0) {
      try {
        await Parcels.forcePin({
          parcelCodes: forcedParcelCode,
          operationId: this.operation.id,
          userPin: pin,
        })
        this.setState({ confirming: true }, this._doConfirm)
      } catch (e) {
        showToast({
          title: __(T.simple_parcel_list.invalid_pin),
          status: 'error',
        })
      }
    } else {
      this.setState({ confirming: true }, this._doConfirm)
    }
  }

  userPinClose = () => {
    this.setState({ visibleUserPin: false })
  }

  _doConfirm = async () => {
    const { parcelsByAsn, formData } = this.state
    try {
      const confirmData = getDataFromSchema(formData, this.formSchema)
      await SimpleParcelListExtensions.beforeConfirm(this.operation, confirmData, parcelsByAsn[''])
      const confirmResult = await SimpleParcelList.save({
        ...confirmData,
        operationId: this.operation.id,
        operationPlaceId: AppStore.defaultWorkstation!.placeId,
        parcelCodes: this.getDetected().map(({ code }) => code),
      })
      await SimpleParcelListExtensions.afterConfirm(this.operation, confirmData, confirmResult)
      showToast({
        title: __(T.misc.success),
        description: __(T.messages.generic_success, { code: this.operation.description }),
        status: 'success',
      })
      this.setState({ confirming: false })
      if (this.operation.postConfirmAction === 'disabled') {
        this.goBack()
      } else {
        if (
          await askUserConfirmation(
            __(T.confirm.post_confirm_action_title),
            this.operation.postConfirmAction === 'keepInput'
              ? __(T.confirm.post_confirm_action_keep_input)
              : __(T.confirm.post_confirm_action_change_input),
            __(T.misc.no),
            __(T.misc.yes)
          )
        ) {
          if (this.operation.postConfirmAction === 'keepInput') {
            this.onClear()
          } else {
            this.goBack()
          }
        } else {
          this.goDashboard()
        }
      }
    } catch (err) {
      this.setState({ confirming: false })
      showToastError(err, __(T.error.error), this.isModal)
    }
  }

  setForcedParcel = (code) => {
    const tmp: string[] = this.state.forcedParcelCode || []
    tmp.push(code)
    this.setState({
      forcedParcelCode: tmp,
    })
  }

  render() {
    const { formData, loading, parcelsByAsn, loadingParcel, unexpectedDecodedParcels, ignoredEpcs, visibleUserPin } =
      this.state
    const unexpectedCount = Object.keys(unexpectedDecodedParcels).length
    const hasForcedParcel = this.operation.options.customExtensionCode === 'cucinelliOutboundCertificato'
    return (
      <Page
        title={this.operation.description}
        onBackPress={() => this.goBack()}
        loading={loading}
        header={{
          details: {
            data: formData,
            formSchema: this.formSchema,
          },
        }}
        enableEmulation
      >
        {!loading && (
          <>
            <Page.Sidebar>
              <Box flex style={{ overflowY: 'auto' }}>
                <TagCounter
                  detected={this.getDetected().length}
                  expected={parcelsByAsn[''].length - this.getUnexpected().length}
                />
                <AntennaButton
                  onClear={this.onClear}
                  decodeFunction={this.decodeFunction}
                  hideClear={
                    unexpectedCount === 0 &&
                    this.getDetected().length === 0 &&
                    ignoredEpcs.length === 0 &&
                    parcelsByAsn[''].filter((p) => p.__detected || p.__forced).length === 0
                  }
                />
                {(unexpectedCount > 0 || ignoredEpcs.length > 0) && <Spacer />}
                <Box row justify="space-evenly">
                  {unexpectedCount > 0 && (
                    <SmallTextCounter
                      onClick={this.openUnexpectedParcel}
                      status="warning"
                      title={__(T.simple_parcel_list.unexpected)}
                      counter={unexpectedCount}
                    />
                  )}
                  {unexpectedCount > 0 && ignoredEpcs.length > 0 && <Spacer />}
                  {ignoredEpcs.length > 0 && (
                    <SmallTextCounter
                      onClick={this.openIgnoredEpcs}
                      status="ignored"
                      title={__(T.misc.ignored)}
                      counter={ignoredEpcs.length}
                    />
                  )}
                </Box>
              </Box>
              {this.showConfirmButton()}
            </Page.Sidebar>
            <Page.Content notBoxed>
              {loadingParcel ? (
                <Box center flex>
                  <Spinner thickness="5px" speed="0.65s" color="#e0e0e0" size="xl" />
                  <Subtitle>{__(T.simple_parcel_list.loading)}</Subtitle>
                </Box>
              ) : (
                <GroupedParcelList
                  operation={this.operation}
                  parcels={parcelsByAsn['']}
                  removeParcel={(parcel) => this.removeParcel(parcel)}
                  refresh={() => this.forceUpdate()}
                  forcedParcelCallback={hasForcedParcel ? this.setForcedParcel : undefined}
                />
              )}
            </Page.Content>
          </>
        )}

        {loadingParcel && <Layer />}

        {hasForcedParcel && (
          <UserPinModal onConfirm={this.userPinConfirm} onClose={this.userPinClose} visible={visibleUserPin || false} />
        )}
      </Page>
    )
  }
}

const Subtitle = styled.div`
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 19px;
  display: flex;
  align-items: center;
  color: grey;
  margin-top: 20px;
`

const Layer = styled.div`
  position: fixed;
  top: 0px;
  left: 0px;
  background-color: #fff;
  width: 100%;
  height: 100%;
  z-index: 100;
  opacity: 0;
`
