import { useState } from 'react'
import { ShippingParcel } from 'stylewhere/api'
import { Box, Button, Input, Modal, Spacer } from 'stylewhere/components'
import { T, __ } from 'stylewhere/i18n'
import { showToast } from 'stylewhere/shared/utils'
import { Parcels } from 'custom/cucinelli/api/Parcels'

interface Props {
  operationId: string
  parcel: ShippingParcel | false
  onClose: (forcedParcel?: ShippingParcel) => void
  visible: boolean
}

/** Usata nella Simple Parcel List Outbound BTHP */
export const ForceParcelByUserPinModal: React.FC<Props> = ({ operationId, parcel, onClose, visible }) => {
  const [userPin, setUserPin] = useState('')

  if (!parcel) return null

  const confirmForce = async () => {
    if (!userPin) return

    try {
      await Parcels.forcePin({
        parcelCodes: [parcel.code],
        operationId,
        userPin,
      })

      preOnClose(parcel)
    } catch (e) {
      /** @todo Verificare il tipo di errore */
      showToast({
        title: __(T.simple_parcel_list.invalid_pin),
        status: 'error',
      })
    }
  }

  const preOnClose = (_parcel?: any) => {
    setUserPin('')
    onClose(_parcel)
  }

  return (
    <Modal
      visible={visible}
      onClose={preOnClose}
      size="lg"
      title={__(T.simple_parcel_list.force_parcel, { code: parcel.code })}
    >
      <Box>
        <Input
          onChange={(value) => setUserPin(value)}
          value={userPin}
          showClear={false}
          type="password"
          label={__(T.simple_parcel_list.force_parcel_desc)}
        />
        <Spacer />
        <Box row justify="end">
          <Button onClick={preOnClose} variant="default">
            {__(T.misc.cancel)}
          </Button>
          <Spacer />
          <Button onClick={() => confirmForce()} disabled={!userPin}>
            {__(T.misc.confirm)}
          </Button>
        </Box>
      </Box>
    </Modal>
  )
}
